import { DatePipe } from '@angular/common';
import { QueryList } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { AppModule } from '@app/app.module';
import { TranslateService } from '@ngx-translate/core';
import { IAddress } from '@shared-models/address.model';
import { DataDisplayDesktopComponent } from '@shared/gui/structural-components/data-display-components/data-display-component/desktop/data-display.desktop.component';
import {
	endOfMonth,
	endOfWeek,
	format,
	parseISO,
	setDay,
	startOfMonth,
	startOfWeek,
	subMonths,
	subWeeks,
	isSameDay,
	set,
	startOfQuarter,
	subQuarters,
	endOfQuarter,
	addWeeks,
	isWithinInterval,
	subHours,
	addHours,
	isAfter,
} from 'date-fns';
import { isString, isNumber, isEmpty } from 'lodash';
import { ExtendedCalendarView, Language, VacationTimeFrame } from './enums';
import { IDataQuery, IDateRange } from './interfaces';
import { DataDisplayMobileComponent } from '@shared/gui/structural-components/data-display-components/data-display-component/mobile/data-display.mobile.component';
import { ICalendarEvent } from '@shared-models/calendar-event.model';
import { map, Observable } from 'rxjs';

/**
 * A helper function to encode the data query that is passed to the query params of the api url
 * @param query The {@link IDataQuery} data query
 * @returns An encoded string to include in the URL
 */
export const formatQueryUrl = (query: IDataQuery): string => encodeURIComponent(JSON.stringify(query));

/**
 * A helper function that checks if a number (as number or string) is a valid number
 * and returns the sanitized number
 * @param value The number as number or string
 * @returns An object containing whether it's valid and the sanitized number
 */
export const validateNumber = (value: number | string): { valid: boolean; value: number } => {
	if (isString(value) && (value as string).replace(/ /g, '').length > 0) {
		value = Number(value);
	}

	return { valid: isNumber(value), value: value as number };
};

/**
 * A helper function to shorten a name of a person
 * Ex. Jos Van der Meulen -> Jos V. d. M.
 * @param firstname The firstname
 * @param lastname Then lastname
 * @returns A shortened name string
 */
export const getShortNameString = (firstname: string, lastname: string): string =>
	`${firstname} ${lastname
		.split(' ')
		.map((lastname) => lastname.slice(0, 1))
		.join('. ')}.`;

/**
 * A helper function to format a {@link IAddress} object into a string
 * @param _address The address that needs to be formatted into a string
 * @returns The formatted address as string
 */
export const getAddressString = (_address: IAddress | null): string =>
	_address && _address.street
		? `${_address.street} ${_address.number}${_address.floor ? ` ${_address.floor}` : ''}, ${_address.zipcode} ${_address.city}`
		: null;

export const addressesMatch = (address1: IAddress | null, address2: IAddress | null): boolean =>
	address1 &&
	address2 &&
	address1.street === address2.street &&
	address1.number === address2.number &&
	address1.floor === address2.floor &&
	address1.country === address2.country &&
	address1.city === address2.city &&
	address1.zipcode === address2.zipcode;

/**
 * Marks all controls in a form group as touched
 * This is relevant to show the validation errors
 * @param formGroup - The form group to touch
 */
export const markFormGroupTouched = (formGroup: UntypedFormGroup | AbstractControl): void => {
	if (formGroup) {
		Object.values((formGroup as any).controls).forEach((control: UntypedFormGroup) => {
			control.markAsTouched();
			if (control.controls) {
				markFormGroupTouched(control);
			}
		});
	}
};

/**
 * Get all fields that are not valid
 * @param formGroup - The form group
 * @returns the invalid fields
 */
export const getInvalidFields = (
	formGroup: UntypedFormGroup | AbstractControl
): Array<{ field: string; errors: Array<string> }> => {
	const fields = new Array();
	if (formGroup) {
		Object.values((formGroup as any).controls).forEach((control: UntypedFormGroup) => {
			if (control.controls) {
				getInvalidFields(control);
			} else if (!control.valid) {
				const parentFormgroup = control.parent.controls;
				fields.push({
					field: Object.keys(parentFormgroup).find((name) => control === parentFormgroup[name]),
					errors: control.errors,
				});
			}
		});
	}
	return fields;
};

/**
 * Duplicate form fields value on change
 * @param form The form
 * @param key1 The key of field 1
 * @param key2 The key of field 2
 */
export const duplicateFormFieldValues = (form: UntypedFormGroup, key1: string, key2: string): void => {
	form.get(key1).valueChanges.subscribe(async () => {
		if (
			form.get(key1).valid &&
			(isEmpty(form.get(key2).value) ||
				(form.get(key1).value as string)?.slice(0, -1) === form.get(key2).value ||
				(form.get(key1).value as string).includes(form.get(key2).value))
		) {
			form.get(key2).patchValue(form.get(key1).value, { emitEvent: false });
		}
	});
	form.get(key2).valueChanges.subscribe(async () => {
		if (
			form.get(key2).valid &&
			(isEmpty(form.get(key1).value) ||
				(form.get(key2).value as string)?.slice(0, -1) === form.get(key1).value ||
				(form.get(key2).value as string).includes(form.get(key1).value))
		) {
			form.get(key1).patchValue(form.get(key2).value, { emitEvent: false });
		}
	});
};

/**
 * A helper method to check whether a form has any keys set
 * can be used when checking whether it needs to be autofilled,
 * or not when any value is inputted by the user
 * @param form The form
 * @param ignoredKeys The ignored keys
 * @returns Whether the form has any filled in inputs
 */
export const formHasSetKeys = (form: UntypedFormGroup, ignoredKeys?: Array<string>): boolean => {
	for (const key of Object.keys(form.value)) {
		if ((form.get(key) as UntypedFormGroup).controls) {
			return formHasSetKeys(form.get(key) as UntypedFormGroup, ignoredKeys);
		} else if (!isEmpty(form.get(key).value) && !ignoredKeys.includes(key)) {
			return true;
		}
	}
	return false;
};

/**
 * Get the languages based on the {@link Language} enum
 * formatted for the form-select
 * @returns the languages
 */
export const getLanguages = (): Array<{ value: string; label: string }> =>
	Object.keys(Language).map((key) => ({
		value: key,
		label: AppModule.injector.get(TranslateService).instant(`LANGUAGES.${key}`),
	}));

export const addDataDisplayRefreshListener = (
	dataDisplayComponent: DataDisplayDesktopComponent | DataDisplayMobileComponent,
	dataDisplayComponents: QueryList<DataDisplayDesktopComponent | DataDisplayMobileComponent>
): void => {
	if (dataDisplayComponents.length > 0) {
		dataDisplayComponent = dataDisplayComponents.first;
		dataDisplayComponent.refreshData();
	}
	dataDisplayComponents.changes.subscribe((comps: QueryList<DataDisplayDesktopComponent>) => {
		dataDisplayComponent = comps.first;
		comps.first?.refreshData();
	});
};

/**
 * Get the current calendar event of an array of calendar events,
 * with an optional margin of in hours hours before and after the calendar event
 * @param calendarEvents The Calendar events
 * @param margin The optional margin before and after in hours
 * @returns The current calendar event
 */
export const getCurrentCalendarEvent = (calendarEvents: Array<ICalendarEvent>, margin: number = 0): ICalendarEvent => {
	return calendarEvents?.filter((calendarEvent) =>
		isWithinInterval(new Date(), {
			start: subHours(new Date(calendarEvent.startsAt), margin),
			end: addHours(new Date(calendarEvent.endsAt), margin),
		})
	)?.[0];
};

/**
 * Get the next calendar event of an array of calendar events
 * @param calendarEvents The Calendar events
 * @returns The current calendar event
 */
export const getNextCalendarEvent = (calendarEvents: Array<ICalendarEvent>): ICalendarEvent => {
	return (calendarEvents ?? []).filter((calendarEvent) => isAfter(new Date(calendarEvent.startsAt), new Date()))?.[0];
};

/**
 * A helper method to translate a key into a string
 * @param key The translation key
 * @param params The translation params
 * @returns The translated string
 */
export const translate = (key: string, params?: Object): string => {
	const translateService: TranslateService = AppModule.injector.get(TranslateService);
	return translateService.instant(key, params);
};

/**
 * A helper method to translate a key into a string in a specific language, not the current application language
 * @param key The translation key
 * @param languageCode The language code to translate in
 * @returns The translated string in an observable
 */
export const translateIn = (key: string, languageCode: 'nl-BE' | 'en-GB'): Observable<string> => {
	if (key && languageCode) {
		const translateService: TranslateService = AppModule.injector.get(TranslateService);

		const getNestedValue = (object: object, path: string): string =>
			path.split('.').reduce((o, k) => (o || {})[k], object);

		return translateService
			.getTranslation(languageCode)
			.pipe(map((translations) => getNestedValue(translations, key)));
	}
};

/**
 * @static
 */
export class DateHelper {
	/**
	 * Format a date to the correct timezone
	 * @param date The date
	 * @returns The corrected date
	 */
	public static correctTimezone(date: Date | string): Date {
		return new Date(new DatePipe('nl-be').transform(date, 'yyyy-MM-dd HH:mm:ss', 'Europe/Brussels'));
	}

	/**
	 * A function that returns a {@link IDateRange} object of the previous week
	 * @returns The {@link IDateRange} object
	 */
	public static getPreviousWeek(): IDateRange {
		const lastMonday: Date = subWeeks(setDay(new Date(), 1), 1);
		const lastSunday: Date = endOfWeek(lastMonday, { weekStartsOn: 1 });
		return { startDate: format(lastMonday, 'yyyy-MM-dd'), endDate: format(lastSunday, 'yyyy-MM-dd') };
	}

	/**
	 * A function that returns a {@link IDateRange} object of the previous week
	 * @returns The {@link IDateRange} object
	 */
	public static getPrevious2Weeks(): IDateRange {
		const lastMonday: Date = subWeeks(setDay(new Date(), 1), 2);
		const lastSunday: Date = addWeeks(endOfWeek(lastMonday, { weekStartsOn: 1 }), 1);
		return { startDate: format(lastMonday, 'yyyy-MM-dd'), endDate: format(lastSunday, 'yyyy-MM-dd') };
	}

	/**
	 * A function that returns a {@link IDateRange} object of the previous month
	 * @returns The {@link IDateRange} object
	 */
	public static getPreviousMonth(): IDateRange {
		const beginOfLastMonth: Date = startOfMonth(subMonths(new Date(), 1));
		const endOfLastMonth: Date = endOfMonth(beginOfLastMonth);
		return { startDate: format(beginOfLastMonth, 'yyyy-MM-dd'), endDate: format(endOfLastMonth, 'yyyy-MM-dd') };
	}

	/**
	 * A function that returns a {@link IDateRange} object of the previous quarter
	 * @returns The {@link IDateRange} object
	 */
	public static getPreviousQuarter(): IDateRange {
		const beginOfLastQuarter: Date = startOfQuarter(subQuarters(new Date(), 1));
		const endOfLastQuarter: Date = endOfQuarter(beginOfLastQuarter);
		return { startDate: format(beginOfLastQuarter, 'yyyy-MM-dd'), endDate: format(endOfLastQuarter, 'yyyy-MM-dd') };
	}

	/**
	 * A function that returns a {@link IDateRange} object of the current month
	 * @returns The {@link IDateRange} object
	 */
	public static getCurrentMonth(): IDateRange {
		const beginOfCurrentMonth: Date = startOfMonth(new Date());
		const endOfCurrentMonth: Date = endOfMonth(beginOfCurrentMonth);
		return {
			startDate: format(beginOfCurrentMonth, 'yyyy-MM-dd'),
			endDate: format(endOfCurrentMonth, 'yyyy-MM-dd'),
		};
	}

	/**
	 * A helper function to format a {@link IDateRange} object into a string
	 * @param _dateRange The date range that needs to be formatted into a string
	 * @returns The formatted date range as string
	 */
	public static getDateRangeString(_dateRange: IDateRange): string {
		return `${format(new Date(_dateRange.startDate), 'd/M/yyyy')} - ${format(new Date(_dateRange.endDate), 'd/M/yyyy')}`;
	}

	/**
	 * A helper function to format a date parameter that could be a string but needs to be a Date
	 * @param _date The date as string or date
	 * @returns The formatted date as Date
	 */
	public static parseDate(_date: string | Date): Date {
		if (typeof _date === 'string') {
			return parseISO(_date as string);
		}
		return _date;
	}

	/**
	 * A helper function to format a date parameter that could be a string but needs to be a Date
	 * @param _date The date as string or date
	 * @param _time The time as string
	 * @returns The formatted date as Date
	 */
	public static generateDate(_date: string, _time: string): Date {
		const date = new Date(_date);
		date.setHours(parseInt(_time.slice(0, 2)), parseInt(_time.slice(3, 5)), parseInt(_time.slice(6, 8)));
		return date;
	}

	/**
	 * A helper function that gets the daterange of a date based on the view of the calendar
	 * @param _viewDate The current date
	 * @param _view The current view
	 * @returns The correct daterange for that date and view
	 */
	public static getDateRange(_viewDate: Date, _view: ExtendedCalendarView): IDateRange {
		switch (_view) {
			case ExtendedCalendarView.Month:
				return {
					startDate: format(startOfMonth(_viewDate), 'yyyy-MM-dd'),
					endDate: format(endOfMonth(_viewDate), 'yyyy-MM-dd'),
				};
			case ExtendedCalendarView.Week:
			case ExtendedCalendarView.WorkWeek:
				return {
					startDate: format(startOfWeek(_viewDate, { weekStartsOn: 1 }), 'yyyy-MM-dd'),
					endDate: format(endOfWeek(_viewDate, { weekStartsOn: 1 }), 'yyyy-MM-dd'),
				};
			case ExtendedCalendarView.Day:
				return {
					startDate: format(_viewDate, 'yyyy-MM-dd'),
					endDate: format(_viewDate, 'yyyy-MM-dd'),
				};
		}
	}

	/**
	 * A helper function that gets the {@link VacationTimeFrame} based on the start & end date
	 * @param startDate The start date
	 * @param endDate The end date
	 * @returns The VacationTimeFrame or null when start or end date are null
	 */
	public static getTimeFrameFromDates(startDate: Date, endDate: Date): VacationTimeFrame {
		if (!startDate || !endDate) {
			return null;
		}

		if (!isSameDay(startDate, endDate)) {
			return VacationTimeFrame.MultipleDays;
		}

		if (endDate <= set(endDate, { hours: 13, minutes: 0, seconds: 0 })) {
			return VacationTimeFrame.Morning;
		}

		if (startDate >= set(startDate, { hours: 13, minutes: 0, seconds: 0 })) {
			return VacationTimeFrame.Afternoon;
		}

		return VacationTimeFrame.Day;
	}
}
