import { Component, HostListener } from '@angular/core';

import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppManager } from '@app/modules/shared/managers/app.manager';
import { BackgroundColorService } from '@app/modules/shared/services/background-color.service';
import { NavigationService } from '@app/modules/shared/services/navigation.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { ValidationService } from '@shared-services/validation.service';
import { register } from 'swiper/element/bundle';
register();
@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {
	constructor(
		private readonly platform: Platform,
		private readonly translateService: TranslateService,
		private readonly appManager: AppManager,
		private readonly backgroundColorService: BackgroundColorService,
		private readonly validationService: ValidationService,
		private readonly navigationService: NavigationService
	) {
		this.initializeApp();
	}

	@HostListener('ionModalWillPresent')
	ionModalWillPresent(): void {
		this.backgroundColorService.resetBackgroundColor();
	}

	@HostListener('ionModalWillDismiss')
	ionModalWillDismiss(): void {
		this.backgroundColorService.setPreviousBackgroundColor();
	}

	private initializeApp(): void {
		this.translateService.use('nl-BE');
		void this.platform
			.ready()
			.then(() => {
				this.appManager.loadMenuState();
				if (this.platform.is('capacitor')) {
					void SplashScreen.hide().catch();
				}
			})
			.catch();
		if (this.validationService.isOnMobileTemplate()) {
			this.navigationService.handleMobileModalBackBehavior();
		}
	}
}
