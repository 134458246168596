export enum Environment {
	Production = 'Production',
	Staging = 'Staging',
	Acceptance = 'Acceptance',
	Local = 'Local',
}

export enum Template {
	mobile = 'mobile',
	desktop = 'desktop',
}

export enum StorageKey {
	user = 'user',
	menu = 'menu',
	token = 'token',
	refreshToken = 'refreshToken',
	userDetails = 'userDetails',
	training = 'training',
	actingCompanyId = 'actingCompanyId',
}

export enum ContextKey {
	trainingBooking = 'trainingBooking',
	companyLink = 'companyLink',
}

export enum NavigationType {
	modal,
	popover,
	page,
}

export enum ErrorType {
	Toast = 'toast',
	PermanentToast = 'permanentToast',
	Component = 'component',
	Validation = 'validation',
}

export enum NavigationDataKey {
	message = 'message',
	client = 'client',
	vacation = 'vacation',
	vacations = 'vacations',
	trainerId = 'trainerId',
	action = 'action',
	date = 'date',
	workblock = 'workblock',
	event = 'event',
	skill = 'skill',
	training = 'training',
	trainingTemplate = 'trainingTemplate',
	location = 'location',
	calendarEvent = 'calendarEvent',
	calendarEventStateId = 'calendarEventStateId',
	origin = 'origin',
	step = 'step',
	params = 'params',
	trainees = 'trainees',
	trainee = 'trainee',
	traineeOnTraining = 'traineeOnTraining',
	trainingId = 'trainingId',
	company = 'company',
	companyUser = 'companyUser',
	closingDay = 'closingDay',
	trainingBookingId = 'trainingBookingId',
	cancellation = 'cancellation',
	queryParams = 'queryParams',
	companyId = 'companyId',
	calendarQuery = 'calendarQuery',
	type = 'type',
	certification = 'certification',
	trainingComponent = 'trainingComponent',
	question = 'question',
}

export enum ValidationErrorLevel {
	warning = 'warning',
	error = 'error',
}

export enum OrderDirection {
	asc = 'ASC',
	asc_nulls_first = 'ASC NULLS FIRST',
	asc_nulls_last = 'ASC NULLS LAST',
	desc = 'DESC',
	desc_nulls_first = 'DESC NULLS FIRST',
	desc_nulls_last = 'DESC NULLS LAST',
}

export enum Role {
	PLANNER = 'planner',
	TRAINER = 'trainer',
	CLIENT = 'client',
	ADMIN = 'admin',
}

export enum Action {
	UPDATE = 'update',
	CREATE = 'create',
	DELETE = 'delete',
	SELECT = 'select',
	DUPLICATE = 'duplicate',
}

export enum TableInputFilterSearchType {
	EQ = '$eq',
	ILIKE = '$ilike',
}

export enum InputMode {
	TEXT = 'text',
	DECIMAL = 'decimal',
	NUMERIC = 'numeric',
	TEL = 'tel',
	SEARCH = 'search',
	EMAIL = 'email',
	URL = 'url',
}

export enum FileType {
	Pdf = 'application/pdf',
	Image = 'image/*',
}

export enum Language {
	NL = 'NL',
	EN = 'EN',
}

export enum TrainingType {
	OPEN = 'OPEN',
	CLOSED = 'CLOSED',
}

export enum CalendarEventType {
	Workblock = 'workblock',
	Training = 'training',
	Vacation = 'vacation',
	ClosingDay = 'closing-day',
}

export enum ExtendedCalendarEventType {
	Travel = 'travel',
	Break = 'break',
}

export enum CalenderEventRequestType {
	suggestion,
	general,
}

export enum TrainerEmploymentType {
	Employee = 'employee',
	Freelancer = 'freelancer',
	Volunteer = 'volunteer',
}

export enum WorkblockType {
	Bookable = 'bookable',
	Office = 'office',
	Recovery = 'recovery',
	Sick = 'sick',
}

export enum VacationTimeFrame {
	Morning = 'morning',
	Afternoon = 'afternoon',
	Day = 'day',
	MultipleDays = 'multiple days',
}

export enum UserOnCompanyStatus {
	Pending = 'pending',
	Confirmed = 'confirmed',
}

export enum ExtendedCalendarView {
	Month = 'month',
	Week = 'week',
	Day = 'day',
	WorkWeek = 'workWeek',
}

export enum WorkblockInterval {
	Weekly = 'weekly',
	BiWeekly = 'bi-weekly',
}

export enum StatisticScale {
	Day = 'day',
	Week = 'week',
	Month = 'month',
	Year = 'year',
}

export enum ERPstatus {
	PendingApproval = 'pending-approval',
	PendingCreation = 'pending-creation',
	Created = 'created',
	FailedCreation = 'failed-creation',
}
