import { IACL } from './interfaces';
import { Role } from './enums';
import { isEmpty } from 'lodash';

export const ACL: IACL = {
	clients: {
		roles: [Role.CLIENT],
		routes: {
			home: ['*'],
			'book-training': ['*'],
			trainings: ['*'],
			documenten: ['*'],
			hulpmiddelen: ['*'],
			profiel: ['*'],
		},
	},
	internal: {
		roles: [Role.TRAINER, Role.PLANNER, Role.ADMIN],
		routes: {
			profiel: [Role.TRAINER],
			kalender: ['*'],
			trainings: ['*'],
			training: ['*'],
			'book-training': [Role.PLANNER, Role.ADMIN],
			vakantie: ['*'],
			trainers: [Role.PLANNER, Role.ADMIN],
			trainer: [Role.PLANNER, Role.ADMIN],
			'training-templates': [Role.PLANNER, Role.ADMIN],
			companies: [Role.PLANNER, Role.ADMIN],
			company: [Role.PLANNER, Role.ADMIN],
			surveys: [Role.ADMIN],
			survey: [Role.ADMIN],
			'survey-answers': [Role.ADMIN],
			trainee: [Role.PLANNER, Role.ADMIN],
			administratie: [Role.PLANNER, Role.ADMIN],
			finance: [Role.PLANNER, Role.ADMIN],
			hulpmiddelen: ['*'],
			statistieken: [Role.ADMIN],
			'merge-companies': [Role.ADMIN],
			'merge-trainees': [Role.ADMIN],
		},
	},
};

/**
 * Validate a route & user role against the ACL
 * @param route The route the user want's to navigate to
 * @param role The role of the user
 * @returns Whether the user is allowed or not
 */
export const validateACL = (route: string, role: Role): boolean => {
	const routes = route.split('/').filter((_route) => !isEmpty(_route));
	if (routes.length > 1) {
		/**
		 * Validate if user role has access to parent route
		 */
		const acl = ACL[routes[0]];
		const allowedRoles = acl.roles;
		if (!allowedRoles?.includes('*') && !allowedRoles?.includes(role)) {
			return false;
		}

		/**
		 * Validate if user role has access to actual (child) route
		 */
		const allowedRolesForChild = acl.routes[routes[1]];
		if (!allowedRolesForChild?.includes('*') && !allowedRolesForChild?.includes(role)) {
			return false;
		}
	}
	return true;
};
