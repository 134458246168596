import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, firstValueFrom } from 'rxjs';
export type SupportedLanguages = 'nl-BE' | 'en-GB';

/**
 * The translation service to manage translations
 */
@Injectable({
	providedIn: 'root',
})
export class TranslationService {
	private currentLanguage: SupportedLanguages;
	private readonly languageChangedSubject: Subject<SupportedLanguages>;
	private readonly supportedLanguages: Array<{ code: SupportedLanguages; name: string }> = [
		{ code: 'nl-BE', name: 'Nederlands' },
		{ code: 'en-GB', name: 'English' },
	];

	constructor(private readonly translateService: TranslateService) {
		this.languageChangedSubject = new Subject();
	}

	public async initialize(): Promise<void> {
		await this.changeLanguage(this.translateService.getDefaultLang() as SupportedLanguages);
	}

	public getCurrentLanguage(): SupportedLanguages {
		return this.currentLanguage ?? 'nl-BE';
	}

	public onLanguageChanged(): Subject<SupportedLanguages> {
		return this.languageChangedSubject;
	}

	public getSupportedLanguages(): Array<{ code: SupportedLanguages; name: string }> {
		return this.supportedLanguages;
	}

	public async changeLanguage(language: SupportedLanguages): Promise<void> {
		this.currentLanguage = language;
		await firstValueFrom(this.translateService.use(language));
		this.languageChangedSubject.next(language);
	}

	public async translate(translationKey: string): Promise<string> {
		return this.translateService.instant(translationKey);
	}
}
